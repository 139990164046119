import React, { useRef } from 'react';
import AppointmentFormController from '@/components/AppointmentForm/AppointmentFormController';
import AppointmentConfirm from '@/components/AppointmentConfirm/AppointmentConfirm';
import Modal from '@/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticated } from '@/store/selectors';
import QuickSignUpController from '@/components/QuickSignUpController';
import StatusModal from '@/components/StatusModal/StatusModal';
import styles from '@/components/Appointment/Appointment.module.scss';
import Button from '@/components/Button/Button';
import RequestError from '@/utils/RequestError';
import Loader from '@/components/Loader';
import { useHistory } from 'react-router';
import { submitAppointment } from '@/api';
import { unsetAppointmentAction } from '@/store/actions';

function AppointmentController() {

	const [stage, setStage] = React.useState('form'); // form, confirm, signup, submitting, success
	const { id, salonId, services, date, time, comment = '', notification } = useSelector((state) => state.appointment);
	const isAuth = useSelector(getIsAuthenticated);
	const [submitError, setSubmitError] = React.useState(null);
	const [notFoundError, setNotFoundError] = React.useState(false);
	const quickSignUpToken = React.useRef();
	const submittingStateRef = useRef(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const hasError = notFoundError || submitError;

	const setFormStage = React.useCallback(() => {
		setStage('form');
	}, []);

	const setConfirmStage = React.useCallback(() => {
		setStage('confirm');
	}, []);

	const submit = React.useCallback(async () => {
		if (submittingStateRef.current) {
			return false;
		}
		submittingStateRef.current = true;
		setStage('submitting');
		try {
			const appointmentData = {
				salon: salonId,
				services,
				date,
				time,
				comment: comment.trim(),
				id,
				notification,
			};
			const result = await submitAppointment(appointmentData, quickSignUpToken.current);
			setStage('success');
			dispatch(unsetAppointmentAction());
			quickSignUpToken.current = null;
			if (window.ym) {
				if (result) {
					window.ym(21520873, 'reachGoal', 'record', {
						appointment_id: result[0],
					});
				}
			}
		} catch (e) {
			if (e instanceof RequestError) {
				if (e.code === 404 || e.code === 403) {
					setNotFoundError(true);
				} else {
					setSubmitError(e.message);
				}
			} else {
				throw e;
			}
		} finally {
			submittingStateRef.current = false;
		}
	}, [salonId, services, date, time, comment, id, notification, dispatch]);

	const handleConfirm = React.useCallback(() => {
		if (!isAuth) {
			setStage('signup');
		} else {
			if (submittingStateRef.current) {
				return;
			}
			submit();
		}
	}, [isAuth, submit]);

	const handleQuickSignUp = React.useCallback((token) => {
		quickSignUpToken.current = token;
		submit();
	}, [submit]);

	const handleFinish = React.useCallback(() => {
		history.replace(isAuth ? '#profile' : '#');
	}, [isAuth, history]);

	const resetAppointment = React.useCallback(() => {
		dispatch(unsetAppointmentAction());
		setStage('form');
	}, [dispatch]);

	const dismissAppointment = React.useCallback(() => {
		history.replace('#');
	}, [history]);

	const dismissError = React.useCallback(() => {
		setSubmitError(null);
		setStage('form');
	}, []);

	React.useEffect(() => {
		return () => {
			submittingStateRef.current = false;
		};
	}, []);

	if (hasError) {
		return <>
			<StatusModal
				isOpen={submitError !== null}
				modalText={submitError}
				status={'error'}
				buttonText={'ОК'}
				onClose={dismissError}
				onButtonClick={dismissError}
			/>
			<Modal
				onClose={() => setNotFoundError(true)}
				isOpen={notFoundError}
				className={styles.appointmentNotFoundModal}
			>
				<div className={styles.appointmentNotFoundModalContent}>
					Не удалось изменить запись. <br/>
					Возможно, запись была отменена.
				</div>
				<div className={styles.appointmentNotFoundActions}>
					<Button
						onClick={resetAppointment}
						className={styles.appointmentNotFoundAction}
						shadowColor={'satin'}
					>
						Отмена
					</Button>
					<Button
						onClick={() => {
						}}
						className={styles.appointmentNotFoundAction}
						shadowColor={'yellow'}
					>
						Записаться
					</Button>
				</div>
			</Modal>
		</>;
	}

	let content;

	switch (stage) {
		case 'form':
			content = <AppointmentFormController
				onSubmit={setConfirmStage}
			/>;
			break;
		case 'confirm':
			content = <Modal
				isOpen
				back={setFormStage}
				onClose={setFormStage}
				title={<>Проверьте, пожалуйста, <br/> данные записи</>}
			>
				<AppointmentConfirm
					onSubmit={handleConfirm}
				/>
			</Modal>;
			break;
		case 'signup':
			content = <QuickSignUpController
				onSubmit={handleQuickSignUp}
				onCancel={setConfirmStage}
			/>;
			break;
		case 'submitting':
			content = <Modal isOpen className={styles.appointmentSubmittingModal}>
				<Loader/>
			</Modal>;
			break;
		case 'success':
			content = <StatusModal
				isOpen
				status={'success'}
				modalText={'Запись успешно создана'}
				buttonText={'ОК'}
				onButtonClick={handleFinish}
				onClose={dismissAppointment}
			/>;
	}

	return content;
}

export default React.memo(AppointmentController);
