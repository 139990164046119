import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

import Button from '@/components/Button/Button';
import YmapAndSalons from '@/components/SalonsListController/SalonsListController';
import NewsList from '@/components/NewsList/NewsList';

import styles from './SingleNews.module.scss';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import { Picture } from '@/components/Picture';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCityId } from '@/store/selectors';
import PageNotFound from '@/components/PageNotFound';
import { initAppointmentAction } from '@/store/actions';

function SingleNews({ content }) {
	const page = content;
	const history = useHistory();
	const dispatch = useDispatch();
	const currentCityId = useSelector(getSelectedCityId);
	const vars = page.templateVars?.pageVars;
	const fullText = vars?.fullText || '';
	const title = page.title;

	const date = moment(page.publishedAt);

	const mainImage = useMemo(() => {
		if (vars?.image?.image) {
			return vars.image.image.find((i) => i.type === 'fullscreen-mobile');
		} else {
			return null;
		}
	}, [vars?.image?.image]);

	if (!vars?.cities?.includes(currentCityId)) {
		return (<PageNotFound/>);
	}

	const handleAction = () => {
		if (!vars.action) {
			history.push('#appointment');
		} else {
			if (vars.action.type === 1) {
				dispatch(initAppointmentAction({
					salonId: vars.action.appointment.salon?.id ?? null,
					services: vars.action.appointment.service ? [{
						id: vars.action.appointment.service.id,
						amount: 1,
					}] : undefined,
				}));
				history.push('#appointment');
			} else {
				window.open(vars.action.externalLink, '_blank');
			}
		}
	};

	return (<div className={styles.container}>
		<div className={styles.content}>
			<Helmet>
				<meta property={'og:type'} content={'article'}/>
				<meta property={'og:article:published_time'} content={date.format('YYYY-MM-DD')}/>
				{mainImage && (
					<meta property={'og:image'} content={mainImage.link}/>
				)}
				{mainImage && (
					<meta property={'og:image:width'} content={mainImage.resolution.split('x')[0]}/>
				)}
				{mainImage && (
					<meta property={'og:image:height'} content={mainImage.resolution.split('x')[1]}/>
				)}
			</Helmet>
			<div className={styles.imgBox}>
				<Picture
					className={styles.img}
					image={vars.image?.image}
					desktopType={'fullscreen-desktop'}
					mobileType={'fullscreen-mobile'}
					alt={vars.image?.imageAlt}
				/>
			</div>
			<article className={styles.newsContent} itemScope itemType="http://schema.org/NewsArticle">
				<header>
					<time itemProp="datePublished" dateTime={date.format('YYYY-MM-DD')} className={styles.date}>
						{date.format('DD.MM.YYYY')}
					</time>
					<h1 itemProp="name" className={styles.name}>{title}</h1>
				</header>
				<div itemProp="description" className={classNames([styles.article, 'text-content'])}>
					{parseHtml(fullText)}
				</div>
				<div className={styles.appointmentLink}>
					<Button onClick={handleAction}>{vars.actionText || 'Записаться'}</Button>
				</div>
			</article>
		</div>
		<div className={styles.newsList}>
			<NewsList perPage={3} noPagination/>
		</div>
		<YmapAndSalons withCityTitle/>
	</div>);
}

export default withRouter(SingleNews);
